import { EChartsOption } from 'echarts'

interface ChartTheme {
  title: string;
  color: string;
  backgroundColor: string;
}

/// 图表基础option
const baseOption: EChartsOption = {
  title: {
    text: '',
    top: 'bottom',
    left: 'center'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    show: false,
    data: (() => {
      const r: Array<number> = []
      const t = new Date().getTime()
      for (let i = 0; i < 60; i += 1) {
        r.push(t + i)
      }
      return r
    })()
  },
  yAxis: {
    type: 'value',
    show: true,
    axisLabel: {
      fontSize: 10,
      width: 80
    },
    min: 0,
    max: 100
  },
  grid: {
    show: true,
    borderWidth: 2
  },
  series: {
    type: 'line',
    data: (() => {
      const r: Array<number> = []
      for (let i = 0; i < 60; i += 1) {
        r.push(0)
      }
      return r
    })(),
    lineStyle: {
      width: 2,
      color: '#1574F4'
    },
    areaStyle: {
      color: '#EAEFFD'
    },
    showSymbol: false
  }
}

const multiLineOption: EChartsOption = {
  title: {
    text: '',
    top: 'bottom',
    left: 'center'
  },
  tooltip: {
    trigger: 'axis',
    valueFormatter: (value) => (value + 'kb')
  },
  legend: {
    data: []
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    show: false,
    data: (() => {
      const r: Array<number> = []
      const t = new Date().getTime()
      for (let i = 0; i < 60; i += 1) {
        r.push(t + i)
      }
      return r
    })()
  },
  yAxis: {
    type: 'value',
    show: true,
    axisLabel: {
      width: 80,
      fontSize: 10,
      formatter: '{value}k'
    }
  },
  grid: {
    show: true,
    borderWidth: 2
  },
  series: [
    {
      name: '网络输入',
      type: 'line',
      data: (() => {
        const r: Array<number> = []
        for (let i = 0; i < 60; i += 1) {
          r.push(0)
        }
        return r
      })(),
      lineStyle: {
        width: 2,
        color: '#1893F1'
      }
    },
    {
      name: '网络输出',
      type: 'line',
      data: (() => {
        const r: Array<number> = []
        for (let i = 0; i < 60; i += 1) {
          r.push(0)
        }
        return r
      })(),
      lineStyle: {
        width: 2,
        color: '#08E16E'
      }
    }
  ]
}

/// CPU图表主题
const cpuTheme: ChartTheme = {
  title: 'CPU负载',
  color: '#0067F3',
  backgroundColor: '#E7F0FD'
}

/// 内存图表主题
const memoryTheme: ChartTheme = {
  title: '内存使用量',
  color: '#6C3FFF',
  backgroundColor: '#EEE9FE'
}

/// 网络IO
const networkTheme: any = {
  title: '网络IO',
  line1Color: '#1893F1',
  line1Name: '网络输入',
  line2Color: '#08E16E',
  line2Name: '网络输出'
}

/// 磁盘IO
const blockTheme: any = {
  title: '磁盘IO',
  line1Color: '#1893F1',
  line1Name: '磁盘输入',
  line2Color: '#08E16E',
  line2Name: '磁盘输出'
}

function getOption (theme: ChartTheme): EChartsOption {
  const _option = baseOption as any
  _option.title.text = theme.title
  _option.grid.borderColor = theme.color
  _option.series.lineStyle.color = theme.color
  _option.series.areaStyle.color = theme.backgroundColor
  return JSON.parse(JSON.stringify(_option))
}

function getOptionOfMultiLine (theme: any): EChartsOption {
  const _option = multiLineOption as any
  _option.title.text = theme.title
  _option.series[0].lineStyle.color = theme.line1Color
  _option.series[1].lineStyle.color = theme.line2Color
  _option.series[0].name = theme.line1Name
  _option.series[1].name = theme.line2Name
  _option.legend.data[0] = theme.line1Name
  _option.legend.data[1] = theme.line2Name
  return JSON.parse(JSON.stringify(_option))
}

export default {
  cpu: getOption(cpuTheme),
  memory: getOption(memoryTheme),
  network: getOptionOfMultiLine(networkTheme),
  block: getOptionOfMultiLine(blockTheme)
}
