
import { Component, Vue } from 'vue-property-decorator'
import AppInstanceList from '@/components/app-instance-list.vue'
import MonitoringBox from './monitoring-box/index.vue'

@Component({
  name: 'MonitoringCenter',
  components: { AppInstanceList, MonitoringBox }
})
export default class extends Vue {
  get useSsl () {
    return location.protocol === 'https:'
  }
}
