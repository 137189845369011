
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as echarts from 'echarts'

export { EChartsOption } from 'echarts'

@Component({
  name: 'Chart'
})
export default class extends Vue {
  /// echart对象
  chart: any;

  @Prop({
    type: Object,
    default: () => ({})
  })
  option!: echarts.EChartsOption;

  @Prop({
    type: String,
    default: '100%'
  })
  height!: string;

  @Prop({
    type: String,
    default: '100%'
  })
  width!: string;

  @Watch('option')
  onDataChange () {
    this.draw()
  }

  mounted () {
    const chartDom = this.$refs.echart as HTMLElement
    this.chart = echarts.init(chartDom)
    this.draw()
  }

  draw () {
    this.chart.setOption(this.option)
  }
}
