
import { Component, Vue, Prop } from 'vue-property-decorator'
import Chart, { EChartsOption } from '@/components/chart.vue'
import chartOption from './chart.config'
// import appInstanceManger from '@/api/maintain/app-instance-manger'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { ApplicationWebSocket, ContainerStatDetail } from '@/libs/application-websocket'
import appInstanceManger from '@/api/maintain/app-instance-manger'

@Component({
  name: 'MonitoringBox',
  components: { Chart }
})
export default class extends Vue {
  @Prop({
    type: Number,
    default: 0
  })
  applicationId!: number

  @Prop({
    type: Number,
    default: 0
  })
  appInstanceId!: number

  @Prop({
    type: String,
    default: 0
  })
  containerName!: string

  @Prop({
    type: String,
    default: undefined
  })
  ipAddress!: string

  @Prop({
    type: Number,
    default: 10001
  })
  ipPort!: number

  /// option
  cpuOption: EChartsOption = JSON.parse(JSON.stringify(chartOption.cpu))
  memoryOption: EChartsOption = JSON.parse(JSON.stringify(chartOption.memory))
  networkOption: EChartsOption = JSON.parse(JSON.stringify(chartOption.network))
  blockOption: EChartsOption = JSON.parse(JSON.stringify(chartOption.block))

  /// 定时器ID(demo用)
  intervalId!: NodeJS.Timeout

  /// 监控流对象
  flux?: EventSourcePolyfill

  /// 发生了错误
  hasError=false

  /// 错误信息
  errorMessage=''

  // 数据准备中
  ready=true

  /**
   * websocket实例
   */
  websocketInstance?: ApplicationWebSocket

  mounted () {
    this.subscribeContainerStat()
  }

  /**
   * 将数据添加到图表的option
   * @param target 目标图表的option
   * @param data 数据
   * @param showOnTitle 将最新的数据添加到图表标题
   */
  dataHandler (target: EChartsOption, data: number, showOnTitle = false): void {
    const _option = target as any
    if (_option.series.data.length >= 60) {
      _option.series.data.shift()
    }
    _option.series.data.push(data)
    _option.xAxis.data.shift()
    _option.xAxis.data.push(new Date().getTime())

    _option.title.text = _option.title.text.replace(/-.*$/, '').trim()
    if (showOnTitle) {
      // _option.title.text += ` - ${data < 10 ? '0' + data : data}%`
      const tmpData = data.toFixed(3)
      _option.title.text += ` - ${tmpData}%`
    }
  }

  /**
   * 将数据添加到图表的option
   * @param target 目标图表的option
   * @param data 数据
   * @param showOnTitle 将最新的数据添加到图表标题
   */
  dataHandler4MultiLine (target: EChartsOption, data: any, showOnTitle = false): void {
    const _option = target as any
    if (_option.series[0].data.length >= 60) {
      _option.series[0].data.shift()
      _option.series[1].data.shift()
    }
    _option.series[0].data.push(data.input / 1000)
    _option.series[1].data.push(data.outpunt / 1000)
    _option.xAxis.data.shift()
    _option.xAxis.data.push(new Date().getTime())
    _option.title.text = _option.title.text.replace(/-.*$/, '').trim()
    _option.title.text += `-${data.input / 1000}/${data.outpunt / 1000}(输入/输出)`
    if (_option.title.textStyle) {
      _option.title.textStyle.width = 200
      _option.title.textStyle.overflow = 'breakAll'
    } else {
      _option.title.textStyle = {
        width: 200,
        overflow: 'breakAll'
      }
    }
  }

  /// 创建webSocket连接
  subscribeContainerStat () {
    appInstanceManger.getContainerStatMonitorWebSocket(this.appInstanceId, this.ipPort, {
      callback: (resp) => {
        if (resp.error && resp.error !== '') {
          this.hasError = true
          this.errorMessage = resp.error
          return
        }
        this.ready = false
        if (resp.running) {
          this.hasError = false
          this.updateCharts(resp.statInfo as any)
        } else {
          this.hasError = true
          this.errorMessage = '容器未运行'
        }
      },
      fixed: undefined,
      onError: (event) => {
        this.hasError = true
        event
        this.errorMessage = '连接错误'
      }
    }).then((ws) => {
      // 添加到实例
      this.websocketInstance = ws
    }).catch((err) => {
      this.hasError = true
      this.errorMessage = err
    })
  }

  /** 修改图表数据 */
  updateCharts (statInfo: ContainerStatDetail) {
    this.updateChartItem(
        this.$refs.cpuChart as any,
        this.cpuOption,
        statInfo.cpu
    )
    this.updateChartItem(
        this.$refs.memoryChart as any,
        this.memoryOption,
        statInfo.memPerc
    )
    this.updateChartItem4MultiLine(
        this.$refs.networkChart as any,
        this.networkOption,
        // statInfo.netInput, statInfo.netOutput]
        { input: statInfo.netInput, outpunt: statInfo.netOutput }
    )
    this.updateChartItem4MultiLine(
        this.$refs.blockChart as any,
        this.blockOption,
        // statInfo.netInput, statInfo.netOutput]
        { input: statInfo.blockInput / 1024, outpunt: statInfo.blockOutput / 1024 }
    )
  }

  updateChartItem (
    chart: Element | Vue | Vue[] | Element[] | Chart,
    option: EChartsOption,
    data: number
  ): void {
    const draw = (chart as Chart).draw
    this.dataHandler(option, data, true)
    draw()
  }

  updateChartItem4MultiLine (
    chart: Element | Vue | Vue[] | Element[] | Chart,
    option: EChartsOption,
    data: any
  ): void {
    const draw = (chart as Chart).draw
    this.dataHandler4MultiLine(option, data, false)
    draw()
  }

  /**
   * 释放资源
   */
  destroyed () {
     this.websocketInstance?.close()
  }
}
